@font-face {
    font-family: 'Inter';
    font-weight: 100;
    src: local('Inter'), url(../fonts/Inter-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 200;
    src: local('Inter'), url(../fonts/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 300;
    src: local('Inter'), url(../fonts/Inter-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: local('Inter'), url(../fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: local('Inter'), url(../fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: local('Inter'), url(../fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: local('Inter'), url(../fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: local('Inter'), url(../fonts/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: local('Inter'), url(../fonts/Inter-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Minisystem';
    font-weight: 900;
    src: local('minisystem'), url(../fonts/minisystem.ttf) format('truetype');
}

.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

body {
    font-family: Inter;
}

h2 {
    font-weight: 300;
    color: rgb(247 170 73);
    font-size: 20px;
}

.flex {
    display: flex;
    flex-direction: row;
}

.Header {
    background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
    width: calc(100vw - 25px);
    min-width: calc(100vw - 6px);
    max-width: calc(100vw - 6px);
    height: 70px;
    vertical-align: middle;
    margin-top: -15px;
    margin-bottom: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.Title {
    vertical-align: middle;
    margin-left: 30px;
    display: inline-block;
    font-size: 40px;
}

.MainScreenBorder {
    border-radius: 6px;
    box-shadow: 0 1.5rem 1rem -.6rem rgb(0 0 0 / 15%);
    border-top: 2px solid #2b2b2b;
    background-image: linear-gradient(47deg, #141414, rgb(32 32 32));
    padding: 10px;
    width: calc(100vw - 25px);
    height: calc(100vh - 120px);
    overflow: hidden;
}

.MainMenuStrip {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: clamp(.75rem, 1vw, 1.5rem);
/*    min-width: 494px;
    max-width: 494px;*/
}

.MainButton {
    padding: clamp(.75rem, 1vw, 2.5rem);
    border-radius: 15px;
    height: 130px;
    grid-column: span 2;
    box-shadow: 0 0 7px rgba(0,0,0,0.6);
    transition: box-shadow 300ms;
    background-image: linear-gradient(176deg, #3a3838, #ffffff17);
    width: 130px;
    max-width: 130px;
    max-height: 130px;
    text-align: center;
}

.SmallButton {
    padding: clamp(.45rem, 0.3vw, 2.1rem);
    border-radius: 15px;
    height: 160px;
    grid-column: span 2;
    box-shadow: 0 0 7px rgba(0,0,0,0.6);
    transition: box-shadow 300ms;
    background-image: linear-gradient(176deg, #3a3838, #ffffff17);
    width: 190px;
    max-width: 190px;
    text-align: center;
}

.ShortButton {
    padding: clamp(.45rem, 0.3vw, 2.1rem);
    border-radius: 15px;
    height: 30px;
/*    grid-column: span 2;*/
    box-shadow: 0 0 7px rgba(0,0,0,0.6);
    transition: box-shadow 300ms;
    background-image: linear-gradient(176deg, #3a3838, #ffffff17);
    width: 140px;
    max-width: 140px;
    text-align: center;
}

.VerySmallButton {
    padding: clamp(.45rem, 0.3vw, 2.1rem);
    border-radius: 15px;
    height: 160px;
    grid-column: span 2;
    box-shadow: 0 0 7px rgba(0,0,0,0.6);
    transition: box-shadow 300ms;
    background-image: linear-gradient(176deg, #3a3838, #ffffff17);
    width: 110px;
    max-width: 110px;
    text-align: center;
    height: 110px;
    max-height: 110px;
}

.VerySmallImg {
    filter: invert(100%) sepia(4%) saturate(1296%) hue-rotate(240deg) brightness(119%) contrast(84%);
    width: 40px;
    height: 40px;
}

.SmallImg {
    filter: invert(100%) sepia(4%) saturate(1296%) hue-rotate(240deg) brightness(119%) contrast(84%);
    width: 80px;
    height: 80px;
}

.ShortImg {
    filter: invert(100%) sepia(4%) saturate(1296%) hue-rotate(240deg) brightness(119%) contrast(84%);
    width: 32px;
    height: 32px;
}

.HeaderImg {
    filter: invert(100%) sepia(4%) saturate(1296%) hue-rotate(240deg) brightness(119%) contrast(84%);
}

.MainImg {
    filter: invert(100%) sepia(4%) saturate(1296%) hue-rotate(240deg) brightness(119%) contrast(84%);
    width: 80px;
    height: 80px;
}

.MainCell {
    vertical-align: top;
}

.StatsTable {
    font-size: 13px;
}

.RecentScan {
    box-shadow: 0 0 7px rgba(0,0,0,0.6);
    transition: box-shadow 300ms;
    background-image: linear-gradient(176deg, #1a1a1a, #ffffff17);
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 6px;
    padding-bottom: 4px;
    margin-bottom: 2px;
    color: #A9A9A9;
    border-radius: 10px;
    font-size: 13px;
    border-bottom: 1px solid #555555;
}

.RecentScanItem {
    color: #F3F3F3;
    font-weight: 600;
}

.DigitalBox {
    font: 500 38px Minisystem;
    width: 240px;
    margin-left: 30px;
}

.SmallDigitalBox {
    font: 500 38px Minisystem;
    width: 110px;
    margin-left: 30px;
}

.NumButtonHolder {
    text-align: center;
    padding: 8px;
}

.NumButton {
    font-size: 32px;
}

.SmallNumButton {
    font-size: 16px;
}

.LocationScanDiv {
    width: 600px;
    min-width: 600px;
    vertical-align: top;
}

.SmallLabel {
    font-size: small;
}

.ItemSection {
    visibility: hidden;
    padding-top: 14px;
    width: 500px;
    max-width: 500px;
}

.DupStockUnitDiv {
    box-shadow: 0 0 7px rgba(0,0,0,0.6);
    transition: box-shadow 300ms;
    background-image: linear-gradient(176deg, #1a1a1a, #ffffff17);
    padding: 7px;
    margin-bottom: 2px;
    border-radius: 10px;
    font-size: 15px;
    border-bottom: 1px solid #555555;
}

.DuplicateLocList {
    padding-left: 12px;
    padding-right: 5px;
    color: rgb(247 170 73);
}

.DuplicateItemScroller {
    height: 30vh;
    min-height: 30vh;
    max-height: 30vh;
    overflow-y: scroll;
    width: 1100px;
    max-width: 1100px;
}

.DuplicateDescriptionDiv {
    overflow-x: hidden;
    white-space: nowrap;
    width: 850px;
    max-width: 850px;
    
}

.DuplicateLoc {
    padding-right: 7px;
}

.DuplicateSerial {
    color: rgb(247 170 73);
}

.ToggleItemDisabled {
    border: 1px solid #595959;
    color: #595959;
    border-radius: 5px;
    background: #3E2900;
    padding: 10px;
    margin: 2px;
    font-size: 20px;
    white-space: pre;
    display: table-cell;
    margin-left: 3px;
    margin-right: 3px;
}

.ToggleItemEnabled {
    border: 1px solid #F3F3F3;
    color: #F3F3F3;
    border-radius: 5px;
    background: #AE8633;
    padding: 10px;
    margin: 2px;
    font-size: 20px;
    white-space: pre;
    display: table-cell;
    margin-left: 3px;
    margin-right: 3px;
}

.StocktakeName {
    width: 350px;
}

.SubmitWarning {
    font-size: 18px;
    font-weight: 600;
    color: rgb(247 170 73);
}

.AreaSelectDiv {
    padding-bottom: 2000px;
    overflow-y: hidden;
}

.LoginFrame {
    margin: auto;
    margin-top: 20px;
    width: 900px;
    height: 500px;
    border-radius: 6px;
    box-shadow: 0 1.5rem 1rem -.6rem rgb(0 0 0 / 15%);
    border-top: 2px solid #2b2b2b;
    background-image: linear-gradient(47deg, #141414, rgb(32 32 32));
    text-align: center;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.LoginTitle {
    margin: auto;
    font-size: 70px;
    font-weight: 500;
    width: 900px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 42px;
    color: rgb(247 170 73);
    transition: color 1.5s ease-in-out;
    text-shadow: 0 0 12px #FFDDAA66;
}

.LoginLabel {
    width: 140px;
    min-width: 140px;
    padding-top: 4px;
}

.LoginBox {
    margin-bottom: 6px;
}

.LoginControlContainer {
    text-align: center;
    padding-left: 160px;
}

.LoginImageHolder {
    padding-top: 70px;
}

.TextBox {
    background: #232323BB;
    color: #DDDDDD;
    font-size: 15px;
    padding: 2px;
    border: 1px solid #CCCCCC;
    padding: 4px;
    border-radius: 0px;
}

.LoginButtonContainer {
    padding-left: 420px;
    margin-top: 8px;
}

.Wide {
    width: 340px;
}

.Narrow {
    width: 200px;
}

.SmallNumber {
    width: 75px;
}

.smallbtn {
    background-image: linear-gradient(182deg, rgb(247 170 73), rgb(72 40 0));
    opacity: 0.95;
    height: 26px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: white;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 11px;
    user-select: none;
}

.smallcancelbtn {
    background-image: linear-gradient(182deg, rgb(247 73 73), rgb(72 0 0));
    opacity: 0.95;
    height: 26px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: #BCBCBC;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 11px;
    user-select: none;
}

.smallsuccessbtn {
    background-image: linear-gradient(182deg, rgb(74 250 73), rgb(0 50 0));
    opacity: 0.95;
    height: 26px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: white;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 11px;
    user-select: none;
}

.smalldisablebtn {
    background-image: linear-gradient(182deg, rgb(100 100 100), rgb(70 70 70));
    opacity: 0.95;
    height: 26px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: white;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 11px;
    user-select: none;
}

.btn {
    background-image: linear-gradient(182deg, rgb(247 170 73), rgb(72 40 0));
    opacity: 0.95;
    height: 30px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: white;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    user-select: none;
}

.cancelbtn {
    background-image: linear-gradient(182deg, rgb(247 73 73), rgb(72 0 0));
    opacity: 0.95;
    height: 30px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: white;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    user-select: none;
}

.successbtn {
    background-image: linear-gradient(182deg, rgb(74 250 73), rgb(0 50 0));
    opacity: 0.95;
    height: 30px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: white;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    user-select: none;
}

.disablebtn {
    background-image: linear-gradient(182deg, rgb(100 100 100), rgb(70 70 70));
    opacity: 0.95;
    height: 30px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
    position: relative;
    color: #BCBCBC;
    transition: opacity 500ms ease-in;
    padding-top: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    user-select: none;
}

.btn:active {
    animation: buttonAnimation 300ms forwards;
}

.cancelbtn:active {
    animation: buttonAnimation 300ms forwards;
}

.successbtn:active {
    animation: buttonAnimation 300ms forwards;
}

@keyframes buttonAnimation {
    50% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}

.error-modal {
    background-color: #9A9A9AFD !important;
    border: 3px solid #DD6767 !important;
    color: #FFFFFF !important;
}

.info-modal {
    background-color: #9A9A9AFD !important;
    border: 3px solid #67DD67 !important;
    color: #FFFFFF !important;
}

.question-modal {
    background-color: #9A9A9AFD !important;
    border: 3px solid #66A9C9 !important;
    color: #FFFFFF !important;
}

.swal-button--confirm {
    background-color: #232323 !important;
    color: rgb(247 170 73) !important;
    font-weight: 600;
}

.swal-button--cancel {
    background-color: #232323 !important;
    color: rgb(247 170 73) !important;
    font-weight: 600;
}

.BottomTypes {
    padding-top: 5px;
}

.TypeSpan {
    width: 206px;
    min-width: 206px;
    display: inline-block;
}

.Invisible {
    display: none;
}

.Visible {
    display: inherit;
}

.NewStockTakeRightPane {
    height: 60vh;
    min-height: 60vh;
    max-height: 60vh;
    width: 800px;
    padding-left: 60px;
}

.NewStockTakeLeftPane {
    vertical-align: top;
}

.ButtonLabelMargin {
    margin-top: 5px;
}

.NewStockTakePleaseWait {
    padding: 10px;
    font-size: 15px;
    font-style: italic;
}

.CellAlignTop {
    vertical-align: top;
}

.VerySmallButtonContainer {
    padding-left: 4px;
    padding-right: 16px;
    padding-top: 8px;
    padding-top: 8px;
}

.ScanningConfirmButtons {
    vertical-align: bottom;
    width: 460px;
}

.LeftHandScan {
    vertical-align: top;
    max-width: 390px;
}

.SerialList {
    color: rgb(247 170 73);
}

.ItemListItem {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 9px;
    padding-right: 9px;
    box-shadow: 0 0 7px rgba(0,0,0,0.6);
    transition: box-shadow 300ms;
    background-image: linear-gradient(176deg, #3a3838, #ffffff17);
    margin-bottom: 3px;
    white-space: pre;
    border: 1px solid #666666;
    border-radius: 3px;
    font-size: 13px;
    max-width: 440px;
}

.StockUnitSpan {
    color: #55DD88FF;
}

.POSas3Span {
    color: #33CCDDFF;
}

.POSas2Span {
    color: #EE6600FF;
}

.ManualSpan {
    color: #DD2222FF;
}

.EmptySpan {
    color: #22AAFFFF;
}

.ItemListScroller {
    height: 280px;
    min-height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 475px;
    min-width: 475px;
}

.ItemListPlaceHolder {
    height: 450px;
    min-height: 450px;
}

.AbortScanDiv {
    height: 530px;
    min-height: 530px;
    display: table-cell;
    vertical-align: bottom;
}

.FloatRightDiv {
    padding-left: 390px;
}

.ScanError {
    color: #DD6767;
    margin-top: 25px;
    font-size: 24px;
    width: 470px;
    max-width: 470px;
    border: 1px solid #DD6767;
    padding: 4px;
    background: #33000099;
}

.ScanWarning {
    color: #DDDD67;
    margin-top: 25px;
    font-size: 16px;
    width: 410px;
    max-width: 410px;
    border: 1px solid #CCCC67;
    padding: 4px;
    background: #99990077;
}

.SubmitScanSuccess {
    color: #67DD67;
    margin-top: 25px;
    font-size: 18px;
    width: 340px;
    max-width: 340px;
    border: 1px solid #67DD67;
    padding: 4px;
    background: #00330099;
}

.LocationAsigneeDiv {
    width: 490px;
    max-width: 490px;
    flex-flow: wrap;
    display: flex
}

.AllLocationList {
    height: 50vh;
    min-height: 50vh;
    max-height: 50vh;
    overflow-y: scroll;
    width: 910px;
    max-width: 910px;
    overflow-x: auto;
    overflow-wrap: anywhere;
    padding-top: 15px;
}

.PickerLocationList {
    height: 270px;
    min-height: 270px;
    max-height: 270px;
    overflow-y: scroll;
    width: 300px;
    max-width: 300px;
    overflow-x: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    align-content: baseline;
}

.FilterListCount {
    color: rgb(247 170 73);
    font-size: 12px;
    padding-left: 12px;
}

.AssignButtonSpacer {
    padding-top: 15px;
}

.UpcomingLocations {
    width: 260px;
    min-width: 260px;
    padding-left: 30px;
}

.NextLocation {
    font-weight: 700;
    color: rgb(247 170 73);
    font-size: 28px;
    margin-top: -10px;
}

.EndStockTakeScanGroupTable {
    font-size: 12px;
}

.EndStockTakeSummaryHeader {
    background-image: linear-gradient(182deg, rgb(90 90 90), rgb(30 30 30));
    opacity: 0.95;
    height: 30px;
    border-radius: 0.4rem;
    padding: 0 1rem;
    text-shadow: 0 0 4px #18181873;
    box-shadow: 0 15px 10px -10px black;
}

.EndStockTakeNumber {
    text-align: right;
}

.EndStockTakeUser {
    font-weight: 600;
}

.ReconNoScans {
    /*background-color: rgba(180, 40, 40, 0.4);*/
    background-image: linear-gradient(182deg, rgb(120 120 120), rgb(90 90 90));
    padding: 3px;
    padding-top: 0px;
    margin: 3px;
    height: 56px;
    width: 90px;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
}

.ReconOneScan {
    /*background-color: rgba(50, 50, 255, 0.35);*/
    background-image: linear-gradient(182deg, rgb(90 90 180), rgb(30 30 140));
    padding: 3px;
    padding-top: 0px;
    margin: 3px;
    height: 56px;
    width: 90px;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
}

.ReconTwoScans {
    background-color: rgba(0, 240, 0, 0.3);
    padding: 3px;
    padding-top: 0px;
    margin: 3px;
    height: 56px;
    width: 90px;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
}

.ReconNoScansSelected {
    /*background-color: rgba(250, 20, 20, 0.5);*/
    background-image: linear-gradient(182deg, rgb(100 100 100), rgb(70 70 70));
    padding: 3px;
    padding-top: 0px;
    margin: 1px;
    height: 56px;
    width: 90px;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    border: 2px solid white;
}

.ReconOneScanSelected {
    /*background-color: rgba(20, 20, 255, 0.45);*/
    background-image: linear-gradient(182deg, rgb(60 60 130), rgb(15 15 100));
    padding: 3px;
    padding-top: 0px;
    margin: 1px;
    height: 56px;
    width: 90px;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    border: 2px solid white;
}

.ReconTwoScansSelected {
    background-color: rgba(0, 255, 0, 0.4);
    padding: 3px;
    padding-top: 0px;
    margin: 1px;
    height: 56px;
    width: 90px;
    font-size: 12px;
    text-align: center;
    line-height: 40px;
    border: 2px solid white;
}

.ReconcileList {
    height: 60vh;
    max-height: 60vh;
    overflow-y: scroll;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    width: 440px;
    max-width: 440px;
}

.ToggleItemDisabledSmall {
    border: 1px solid, #373737;
    color: #656565;
    border-radius: 5px;
    background: #3E2900;
    padding: 6px;
    margin: 5px;
    font-size: 16px;
    white-space: pre;
    display: table-cell;
    margin-left: 6px;
    margin-right: 6px;
}

.ToggleItemEnabledSmall {
    border: 1px solid, #F3F3F3;
    color: #F3F3F3;
    border-radius: 5px;
    background: #AE8633;
    padding: 6px;
    margin: 5px;
    font-size: 16px;
    white-space: pre;
    display: table-cell;
    margin-left: 6px;
    margin-right: 6px;
}

.TypeSpanSmall {
    width: 70px;
    min-width: 70px;
}

.LocationFilterEnd {
    margin-top: -8px;
    padding-bottom: 6px;
}

.LocationToAssign {
    background-image: linear-gradient(182deg, rgb(133 133 133), rgb(44 44 44));
    color: #FFFFFF;
    margin: 2px;
    padding: 6px;
    min-width: 106px;
    height: 22px;
    user-select: none;
}

.LocationAssigned {
    background-image: linear-gradient(182deg, rgb(173 203 173), rgb(77 109 77));
    color: #FFFFFF;
    margin: 2px;
    padding: 6px;
    min-width: 106px;
    height: 22px;
    user-select: none;
}

.GroupSelected {
    color: #67DD67;
    font-size: 13px;
    width: 140px;
    max-width: 140px;
    border: 1px solid #67DD67;
    padding: 4px;
    background: #00330099;
    height: 18px;
    min-height: 18px;
    margin-top: 4px;
}

.GroupDiscarded {
    color: #DD6767;
    font-size: 13px;
    width: 140px;
    max-width: 140px;
    border: 1px solid #DD6767;
    padding: 4px;
    background: #33000099;
    height: 18px;
    min-height: 18px;
    margin-top: 4px;
}

.GoodScanArea {
    color: #67DD67 !important;
    padding-left: 20px;
    padding-top: 10px;
    font-weight: 700 !important;
    text-shadow: 0 0 18px #AAFFAAFF !important;
    font-size: 22px !important;
}

.ReactModal__Content {
    position: absolute;
    background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
    box-shadow: 0 0 20px 0 rgba(220, 160, 75, 0.99);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding-left: 15px;
    padding-top: 15px;
}

.ModalOverlay {
    position: fixed;
    inset: 0px;
    background: linear-gradient(33.6deg, rgba(0, 0, 0, 0.75) 11.2%, rgb(22, 22, 22) 68.9%);
}

.SmallModal {
    width: 540px;
    height: 420px;
    left: calc(50% - 270px);
    top: calc(50% - 210px);
}

.LargeModal {
    width: 880px;
    height: 530px;
    left: calc(50% - 440px);
    top: 20px;
}

.WideModal {
    width: 980px;
    height: 530px;
    left: calc(50% - 490px);
    top: 20px;
}

.ModalConfirmContainer {
    display: flex;
    flex-direction: row;
    direction: rtl;
    padding-right: 22px;
    padding-top: 12px;
}

.ReconcilliationNextScanLabel {
    font-size: 13px;
    color: #CDCDCD;
    padding-top: 3px;
    max-width: 320px;
}

.POItemDiv {
    width: 604px;
    max-width: 604px;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    border-bottom: 1px solid #777777;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, rgba(50, 50, 50, 0.63) 11.2%, rgb(27, 27, 27) 68.9%);
    font-size: 13px;
    margin-bottom: 3px;
}

.POItemDescriptionDiv {
    width: 315px;
    min-width: 315px;
    padding-right: 10px;
    padding-left: 2px;
    margin: auto;
}

.POItemPartNoDiv {
    padding-right: 10px;
    width: 135px;
    min-width: 135px;
    color: rgb(247 170 73);
    margin: auto;
}

.POHereButtonDiv {
    margin: auto;
    padding-right: 4px;
    width: 100px;
}

.POScanItemNumber {
    font-size: 20px;
}

.POItemOopsSpan {
    color: rgb(220, 70, 70);
    padding-left: 10px;
}

.POItemContainerDiv {
    height: 360px;
    min-height: 360px;
    max-height: 360px;
    display: block;
    overflow-y: scroll;
}

.SerialSpan {
    font-size: 12px;
    padding-right: 3px;
}

.InputSerial {
    background: #65656599;
    border: solid 1px, #EEEEEE;
    color: #EAEAEA;
    line-height: 1.85 !important;
    outline: none;
    margin-bottom: 2px;
}

.SerialPONumLabel {
    color: rgb(247 170 73);
    padding-right: 12px;
}

.SerialPartNoLabel {
    color: rgb(247 170 73);
    padding-right: 12px;
    padding-left: 34px;
}

.SerialItemBox {
    border-radius: 3px;
    background: linear-gradient(180deg, rgba(50, 50, 50, 0.63) 11.2%, rgb(27, 27, 27) 68.9%);
    overflow-y: scroll;
    height: 230px;
    min-height: 230px;
    font-size: 11px;
}

.SerialItemHolderBox {
    background-color: transparent;
    font-size: 11px;
    margin-bottom: 3px;
    border-bottom: 1px solid #777777;
    border-radius: 3px;
    width: 700px;
    min-width: 700px;
    max-width: 700px;
    padding: 5px;
}

.EmptyLocationAtFinish {
    margin: auto;
    font-size: 16px;
    font-weight: 500;
    width: 900px;
    margin-top: 4px;
    margin-bottom: 9px;
    color: rgb(247 170 73);
    text-shadow: 0 0 12px #FFDDAA66;
}

.EmptyLocationAtFinishHolder {
    background: linear-gradient(180deg, rgba(50, 50, 50, 0.63) 11.2%, rgb(27, 27, 27) 68.9%);
    font-size: 13px;
    margin-bottom: 3px;
    border-bottom: 1px solid #777777;
    border-radius: 3px;
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    padding: 5px;
}

.LegacySerialSource {
    color: #EE5544;
    font-size: 18px;
}

.NonLegacySerialSource {
    color: #44DD44;
}

.SummaryStat {
    width: 370px;
    max-width: 370px;
    min-width: 370px;
    display: inline-block;
    padding-bottom: 3px;
}

.SummaryStatValue {
    color: rgb(247 170 73);
}

.SmallHelperText {
    color: #CCCCCC;
    font-size: 9px;
    margin-top: -7px;
}

.PortraitCenter {
    margin: auto;
    width: 98%;
}

.PortraitHeader {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
}

.PortraitUnselectedLocation {
    background-image: linear-gradient(182deg, rgb(243 133 133), rgb(199 77 77));
    color: #FFFFFF;
    margin: 5px;
    padding: 6px;
    padding-top: 11px;
    min-width: 120px;
    max-width: 120px;
    height: 20px;
    max-height: 20px;
    font-size: 12px;
    user-select: none;
}

.PortraitSerialBlock {
    background-image: linear-gradient(182deg, rgb(190 190 120), rgb(140 140 99));
    color: #FFFFFF;
    margin: 5px;
    padding: 6px;
    padding-top: 11px;
    min-width: 120px;
    max-width: 120px;
    height: 20px;
    max-height: 20px;
    font-size: 12px;
    user-select: none;
}

.PortraitSelectedLocation {
    background-image: linear-gradient(182deg, rgb(173 203 173), rgb(77 109 77));
    color: #FFFFFF;
    margin: 5px;
    padding: 6px;
    padding-top: 11px;
    min-width: 120px;
    max-width: 120px;
    height: 20px;
    max-height: 20px;
    user-select: none;
    font-size: 12px;
}

.PortraitUnselectedLocationWithScans {
    background-image: linear-gradient(182deg, rgb(101 101 193), rgb(46 46 160));
    color: #FFFFFF;
    margin: 5px;
    padding: 6px;
    padding-top: 11px;
    min-width: 120px;
    max-width: 120px;
    height: 20px;
    max-height: 20px;
    user-select: none;
    font-size: 12px;
}

::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
    background-color: #444444;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(200 140 53);
    background-image: -webkit-linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4) 50%, transparent, transparent)
}

::-webkit-scrollbar-thumb:hover {
        background-color: #F64949;
    }